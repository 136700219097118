<template>
  <section :class="$style.container">
    <h2 :class="$style.header">
      続きは普段使っている<br />ブラウザーから進めてください<sup>※</sup>
    </h2>
    <div :class="[$style.instruction, $style.container__instruction]">
      <p>
        画面右上の「<em>…</em>」→「<em>ブラウザーで開く</em>」の<br />順にタップして始めましょう！
      </p>
    </div>
    <div :class="[$style.image, $style.container__image]">
      <img
        src="@/assets/images/promptUserToTryLoginWithDefaultBrowser/browser_option_ios.jpg"
        alt=""
      />
    </div>
    <div :class="$style.container__note">
      <p>
        <small
          >※Instagramなどのアプリ内のブラウザーで利用すると、正常に動作しない場合がございます。</small
        >
      </p>
    </div>
  </section>
</template>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;

  @include small-mobile-only {
    @media only screen and (max-width: 320px) {
      padding: 0px;
    }
  }

  &__image {
    margin-top: 16px;
  }

  &__instruction {
    margin-top: 8px;
  }

  &__note {
    margin-top: 16px;
  }
}

.header {
  font-size: 18px;
  color: $color-text-and-object-accent-primary;
  text-align: center;

  @include small-mobile-only {
    @media only screen and (max-width: 320px) {
      font-size: 14px;
    }
  }
}

.image {
  width: 156px;
}

.instruction {
  text-align: center;

  @include small-mobile-only {
    @media only screen and (max-width: 320px) {
      font-size: 10px;
    }
  }

  em {
    color: $color-text-and-object-accent-primary;
    font-weight: bold;
  }
}
</style>
