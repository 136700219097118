<script setup lang="ts">
import PromptUserToLoginWithDefaultBrowserAndroid from './PromptUserToLoginWithDefaultBrowserAndroid.vue';
import PromptUserToLoginWithDefaultBrowserIos from './PromptUserToLoginWithDefaultBrowserIos.vue';
import { useUserAgent } from '~theme/composables/useUserAgent';

const { userAgent } = useUserAgent();
const isRenderedOnIos = computed(() => userAgent.value.os.name === 'iOS');
</script>

<template>
  <ClientOnly>
    <PromptUserToLoginWithDefaultBrowserIos v-if="isRenderedOnIos" />
    <PromptUserToLoginWithDefaultBrowserAndroid v-else />
  </ClientOnly>
</template>
